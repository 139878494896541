import React from "react";
import { Link, useStaticQuery, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Button } from "react-bootstrap";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import Hero from "../../components/hero";
import HorizontalTestimonial from "../../components/horizontal-testimonial";
import { StaticImage } from "gatsby-plugin-image";
import HeroEnd from "../../components/hero-end";

const CoachesCoachingPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				heroImg: file(relativePath: { eq: "16.jpg" }) {
					publicURL
					childImageSharp {
						fluid(quality: 100, maxWidth: 1920) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				daniel: file(
					relativePath: { eq: "testimonials/Vicki-Integrity.jpeg" }
				) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				louise: file(relativePath: { eq: "testimonials/louise-taylor.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				selda: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
				andrew: file(relativePath: { eq: "testimonials/selda-koydemir.jpg" }) {
					childImageSharp {
						gatsbyImageData(quality: 100, width: 400)
					}
				}
			}
		`
	);
	const siteUrl = data.site.siteMetadata.siteUrl;
	const schemaImage = data.heroImg.publicURL;
	const title = "Coaches | Business & Executive Coaching in London";
	const description =
		"Navigating the business world on your own? That's a tough challenge, isn't it? What you really need is someone who gets the whole picture—the highs and lows, the nitty-gritty of business life.";
	const heroImage = data.heroImg.childImageSharp.fluid;
	const daniel = data.daniel.childImageSharp.gatsbyImageData;
	const andrew = data.andrew.childImageSharp.gatsbyImageData;
	const louise = data.louise.childImageSharp.gatsbyImageData;
	const selda = data.selda.childImageSharp.gatsbyImageData;
	const ogImg = `${siteUrl}${schemaImage}`;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "Industries",
				item: {
					url: `${siteUrl}/industries`,
					id: `${siteUrl}/industries`,
				},
			},
			{
				"@type": "ListItem",
				position: 3,
				name: "Coaches",
				item: {
					url: `${siteUrl}/industries/coaches`,
					id: `${siteUrl}/industries/coaches`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={title}
				description={description}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/industries/coaches`,
					title: `${title}`,
					description: `${description}`,
					images: [
						{
							url: `${ogImg}`,
							width: 1920,
							height: 1080,
							alt: "Coaches business & executive coaching in London",
						},
					],
				}}
			/>
			<HeroEnd
				isDarken={true}
				backgroundImage={heroImage}
				title="Business and Executive Coach For Coaches"
				description1=""
				description2=""
			/>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							{/* <p>
                As a professional in a leadership position, you’re constantly
                juggling the priorities of your job with everything else in
                life. You’re likely responsible for strategic thinking,
                overseeing operations, developing new approaches, and much more.
              </p> */}
							<p>
								Navigating the business world on your own? That's a tough
								challenge, isn't it? What you really need is someone who gets
								the whole picture—the highs and lows, the nitty-gritty of
								business life. Someone who can help you sketch out your goals
								and actually reach them, and be right there with you when those
								tricky decisions pop up. Your business coach has got to truly
								get you, knows the coaching industry inside out, and understands
								exactly the kind of challenges we face, whether in the boardroom
								or out in the field.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								How I can help you as a business coach
							</h3>
							<p>
								I understand that your daily routine is different and more
								varied than the typical 9 to 5. With over 35 years of experience
								in the business coaching world, I've guided business coaches
								from various backgrounds in launching or expanding their
								ventures.
							</p>
							<p>
								Have you ever felt like you're chasing something more? - more
								fulfilment within your business or perhaps a fresh challenge
								that reignites your enthusiasm for coaching and life beyond the
								client sessions?
							</p>
							<p>
								Why not discover what we can achieve together? A bit of coaching
								might just be the catalyst you need. Let's dive in and see where
								this coaching journey can take us.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-right mr-3 mb-3 ml-lg-3 service-page-body-image"
								src="../../images/diagram.jpg"
							/>
							<h3 className="text-primary pb-3">
								The future of your coaching business
							</h3>

							<p>
								Picture this: you've got a magic crystal ball that lets you see
								into your future. What if you saw yourself:
							</p>
							<ul className="pl-4">
								<li>
									Overflowing with confidence, truly believing in what you're
									capable of?
								</li>
								<li>
									Sharpening those leadership and communication skills that are
									vital for guiding your clients and teams?
								</li>
								<li>
									Making bold, assured choices in your business without
									hesitation?
								</li>
								<li>
									Rediscovering your passion for coaching, reigniting that spark
									that drew you to this career in the first place?
								</li>
								<li>
									Or maybe you're tackling a bigger challenge with excitement?
								</li>
							</ul>
							<p>
								Think about how life could shift if these scenarios weren't just
								possible, but became your new normal.
							</p>
							<p>
								So, what's stopping you from building a successful coaching
								business?
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="bg-primary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">What has been holding you back?</h2>
							<ul className="pl-4">
								<li>
									Feeling overwhelmed with running and managing your business,
									navigating through critical decisions, and juggling multiple
									responsibilities?
								</li>
								<li>
									Making the leap from being part of a team to leading and
									expanding your own business venture?
								</li>
								<li>
									Struggling with realising and utilising your strengths and
									improvement areas?
								</li>
								<li>Looking for clarity in your entrepreneurial journey?</li>
								<li>
									Or are you at a turning point, seeking the best path forward?
								</li>
							</ul>
							<p>
								Especially in the dynamic world of business coaching, we
								encounter challenges that test us. However, it's how we tackle
								these hurdles that truly shapes our professional journeys as
								coaches.
							</p>
							<p>
								That's where I can step in with my business coaching services,
								specifically designed for business coaches eager to establish or
								elevate their ventures. My coaching sessions provide the
								insights, strategies, and support you need to master the
								challenges of business leadership, empowering you to make
								impactful decisions for your career and your clients.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								size="lg"
								as={Link}
								to="/contact-us"
								className="cta-btn cta-btn-gtm"
							>
								Book a discovery meeting
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Achieve new successes with business coaching
							</h2>
							<p>
								As a business coach coaching professionals like you, my mission
								is to empower you to seize the unique opportunities within
								coaching. I understand the pressures of your schedule, which is
								exactly why our coaching sessions are designed to blend
								seamlessly into your business life, making room for even the
								most packed agendas.
							</p>
							<p>
								In our sessions, we'll dive into areas important for both your
								professional growth and personal development:
							</p>
							<ul className="pl-4">
								<li>
									Skill Enhancement and Personal Development: Expanding your
									coaching business while achieving your own goals and
									well-being.
								</li>
								<li>
									Leadership Mastery: Cultivating leadership skills that not
									only motivate your team but also drive substantial progress in
									your business.
								</li>
								<li>
									Efficient Business Management: Navigating the complexities of
									running your own business to ensure smooth operations.
								</li>
								<li>
									Career Mapping: Reflecting on your career journey, pinpointing
									opportunities for growth and satisfaction.
								</li>
							</ul>
							<p>
								What makes my coaching approach stand out is the bespoke and
								tailored nature of the coaching. The sessions are created to
								address your particular challenges and ambitions, to provide you
								with the insights, guidance, and strategies crucial for success
								in the business world and making a meaningful difference to both
								yourself and your clients.
							</p>
							<p>
								Why not kick start this journey with a chat with me? It's a
								chance to uncover your potential, promising profound rewards for
								your career and beyond. Let's talk about how we can reach your
								objectives together.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<StaticImage
								className="float-lg-left mr-3 mb-3 mb-lg-1 service-page-body-image"
								src="../../images/12.jpg"
							/>
							<h2 className="text-primary pb-3">Asking the right questions</h2>

							<p>
								As an executive coach, my job centers around asking the right
								questions and carefully listening to what you say, tapping into
								the true sentiment behind your words and your thought process.
							</p>
							<p>
								I learned how to REALLY listen to my clients in a
								non-judgemental way during my 5 years of counselling and
								psychotherapy training.
							</p>
							<p>
								Executive coaching is all about listening properly without
								judging, I will uncover and support you with any false
								assumptions, fears and life-limiting beliefs. These are the
								things that may be holding you back, like a lack of confidence,
								imposter syndrome etc.
							</p>
							<p>
								Executive coaching like this can help you manage these thoughts
								and feelings, so they no longer impact your personal or
								professional life, while working alongside you to gain focus and
								make a significant impact on your life and business.
							</p>
							<p>
								Empowering you to think about the reasons ‘why you can’, rather
								than ‘why you can’t.’
							</p>
							<p>
								Let’s decide what you want, make a plan and use coaching to make
								it happen!
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-tertiary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="pb-3">
								Why a business and executive coach is perfect for you
							</h2>
							<p>
								In navigating the journey of running my own show, I've seen the
								whole spectrum - from the peak moments that make it all
								worthwhile to those tough spots every business faces. Mixing my
								expertise for business, with my Management Studies (DMS) and an
								MBA, with a five-year deep dive into counselling and
								psychotherapy, and nearly four decades in the business world, I
								know how to succeed in the coaching business world.
							</p>
							<p>
								And now, I'm here for you. With a coaching business that's not
								only thriving but also profitable, and a bunch of clients who
								trust me to steer their ship. Whether you're just starting out
								or looking to scale up, I've been there. In our sessions, you'll
								get a blend of business strategy and emotional intelligence -
								because managing the ups and downs of your coaching business
								shouldn't be a solo job. Let's chat about how we can make your
								coaching business dreams a reality.
							</p>
						</Col>
					</Row>
				</Container>
			</section>
			<section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Positive feedback from my coaching clients
							</h2>
						</Col>
					</Row>

					<HorizontalTestimonial
						name="Victoria Ramsden. Integrity Executive Coaching."
						review="I had been looking for a coach for some time and had spoken to a few coaches before I remembered I knew of Jason. I knew immediately he was what I had been looking for. I’ve had my business for a while and knew I needed another level of distilling what I was offering to more closely reflect my personal values, purpose and aims for my coaching business. As any good coach knows, when we are working solo, even though we know deep down what we want, it’s hard to step outside of ourselves and land on the right language to appropriately express what we do, to those clients we serve best. Jason has held the mirror up, generously shared his rich insights and experiences which have been so valuable in cementing things that I’ve logically known but that I needed to sink in and be applied to my specific context.  Others say this too, Jason’s approach is no-faff and he gets the job done and done brilliantly. We haven’t yet concluded our time together and yet I have already seen the impact of working with Jason, and as we landed on my updated strap line, it literally bought tears of joy (we laughed about this!), having been grappling with this for months. Coming from another coach, I’d like to think this is high praise and truly deserved for the value Jason has given me and my business!"
						linkedIn="https://www.linkedin.com/in/victoria-ramsden-abb8a45/"
						profilePic={daniel}
					/>

					<HorizontalTestimonial
						name="Louise E Taylor. Creative Leadership Coach."
						review="I can highly recommend Jason – he is an excellent coach and mentor. He has enabled me to resolve problems, gain clarity and move my business forward. And he’s got a great sense of humour – invaluable!"
						linkedIn="https://www.linkedin.com/in/louise-taylor-134b4014/"
						profilePic={louise}
					/>

					<HorizontalTestimonial
						name="Dr Selda Koydemir. Psychotherapist and coach for adults"
						review="Jason has helped me get clear on what I really want to do, where I want to go, what strengths I have and how I can use them to achieve my goals, and encouraged me to be more proactive around my business ideas. He has a friendly and challenging attitude which has put me at ease, but also question my mindset and behaviours at the right time. I’m very glad that I’ve had the chance to work with such a great mentor."
						linkedIn="https://www.linkedin.com/in/seldakoydemir/"
						profilePic={selda}
					/>
				</Container>
			</section>
			{/* <section className="pt-5">
				<Container>
					<Row>
						<Col>
							<h3 className="text-primary pb-3">
								Break the cycle with executive coaching in London
							</h3>
							<p>
								In this 24/7 world, leaders, senior managers, owners and
								executives are so busy that they don’t have the time to devote
								to their own development or to the development of the people who
								work for them. In other words, they’re caught in a vicious cycle
								of always “being busy.”
							</p>
							<p>
								In the same way, some struggle and worry about not being able to
								think clearly. You may have an idea, but you can’t seem to get a
								clear picture on what to do next, or perhaps you need to make
								decisions, but you don’t feel confident that the decision you
								make will be the right one.
							</p>
							<p>
								This is precisely why you need executive coaching services. As
								an executive coach in London, I can help you break the cycle of
								always being busy while giving you the confidence to make
								decisions that positively impact your life.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			{/* <section className="py-5">
				<Container>
					<Row>
						<Col>
							<h2 className="text-primary pb-3">
								Ready to achieve professional growth?
							</h2>
							<p>
								While we may not have a crystal ball to predict what lies ahead
								for your business, I can't specify exactly how your educational
								business might transform if you embrace new teaching methods,
								awaken each day brimming with confidence and joy, or
								significantly enhance the performance of your school or tutoring
								business. However, one thing is crystal clear:
							</p>
							<p>If we continue to resist change, nothing will evolve.</p>
							<p>
								It's remarkable how much we can accomplish together when we
								utilise the power of coaching to concentrate and reflect on your
								true aspirations within the education sector.
							</p>
							<p>
								With everything to gain and nothing to lose, why not schedule an
								introductory call with me? Let's explore the possibilities that
								await you and your career.
							</p>
						</Col>
					</Row>
				</Container>
			</section> */}
			<section className="bg-secondary text-white py-5">
				<Container>
					<Row>
						<Col>
							<h3 className="pb-3">Book a discovery call today</h3>
							<p>
								Working as a business coach can be demanding, meaning you aren’t
								focusing on yourself and your business as much as you should be.
							</p>
							<p>
								So, why don’t we arrange a discovery call? It's a great chance
								for us to explore how business coaching could be the
								game-changer you've been looking for. As a business coach
								myself, with a variety of clients and a coaching business that's
								profitable, I've seen firsthand the transformative power of the
								right coaching guidance.
							</p>
							<p>
								P.S. No matter how skilled you are in your field as a coach, the
								truth is, as a business owner, going solo can only get you so
								far. That's where I come in - your go-to for unbiased feedback,
								goal-setting, and the nudge you need when facing those tough
								calls. Let's team up and unlock your full potential.
							</p>
						</Col>
					</Row>
					<Row className="text-center pt-4">
						<Col>
							<Button
								variant="outline-light"
								className="cta-btn cta-btn-gtm"
								size="lg"
								as={Link}
								to="/contact-us"
							>
								Get in Touch
							</Button>
						</Col>
					</Row>
				</Container>
			</section>
			{/* <CtaBanner
        headline="Executive Coaching"
        btnTxt="Book a call"
        btnLink="/contact-us"
        bgColor="light"
      /> */}
		</Layout>
	);
};
export default CoachesCoachingPage;
